import { useStaticQuery, graphql } from "gatsby"

export const usePatientsQuery = () => {


    const data = useStaticQuery(graphql`
    query PatientsQuery {
        wpPage(title: {eq: "PATIENTS"}) {
        ACF_PatientsPage {
            patientsHero {
                image {
                    publicUrl
                }
                text
                tagline
            }

            patientsBlock1 {
                tagline
                content
                ctaTagline
                ctaLabel
                ctaUrl
            }

            patientsBlock2 {
                content
            }

            patientsBlock3 {
                tagline
                description
                column1 {
                    image {
                        publicUrl
                    }
                    title
                    content
                }
                column2 {
                    image {
                        publicUrl
                    }
                    title
                    content
                }
            }

            patientsBlock4 {
                content
            }

            patientsFaqs {
                title
                question1{
                    question
                    answer
                }
                question2{
                    question
                    answer
                }
                question3{
                    question
                    answer
                }
                question4{
                    question
                    answer
                }
                question5{
                    question
                    answer
                }
                question6{
                    question
                    answer
                }
                question7{
                    question
                    answer
                }
                question8{
                    question
                    answer
                }
                question9{
                    question
                    answer
                }
                question10{
                    question
                    answer
                }
            }

            patientsBlock5 {
                content
            }

            patientsReferences {
                references
            }

            patientsMetadata {
                metaTitle
                metaDescription
            }
        }
        }
    }     
    `)
    return data.wpPage.ACF_PatientsPage;
}