import * as React from "react";

const BlockFive = ({data}) => {
    return (
        <div className="row-fluid-wrapper row-depth-1 row-number-22 ">
            <div className="row-fluid ">
                <div className="span12 widget-span widget-type-cell full-width" data-widget-type="cell" data-x="0" data-w="12">

                    <div className="row-fluid-wrapper row-depth-1 row-number-23 ">
                        <div className="row-fluid ">
                            <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                <div className="row-fluid-wrapper row-depth-1 row-number-24 ">
                                    <div className="row-fluid ">
                                        <div className="span11 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="11">
                                            <div id="hs_cos_wrapper_module_1576523209876443" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1576523209876443_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text"><a id="urology" data-hs-anchor="true"></a>
                                                <h2 className="headlines" style={{textAlign: "center"}}>&nbsp;</h2>
                                                <div dangerouslySetInnerHTML={{__html: data.patientsBlock5.content}}></div>
                                                </span></div>

                                        </div>
                                        <div className="span1 widget-span widget-type-custom_widget "  data-widget-type="custom_widget" data-x="11" data-w="1">
                                            <div id="hs_cos_wrapper_module_1576523230988487" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-25 ">
                                    <div className="row-fluid ">
                                        <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{height:"100px"}} data-widget-type="custom_widget" data-x="0" data-w="12">
                                            <div id="hs_cos_wrapper_module_1576523469863535" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                                <div className="row-fluid-wrapper row-depth-1 row-number-26 ">
                                    <div className="row-fluid ">
                                        <div className="span11 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="11">
                                            <div id="hs_cos_wrapper_module_1575659221848278" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span id="hs_cos_wrapper_module_1575659221848278_" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_rich_text" data-hs-cos-general-type="widget" data-hs-cos-type="rich_text">
                                                <div dangerouslySetInnerHTML={{__html: data.patientsReferences.references}}></div>
                                                </span></div>

                                        </div>
                                        <div className="span1 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="11" data-w="1">
                                            <div id="hs_cos_wrapper_module_1575659227604280" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BlockFive;