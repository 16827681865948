import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/patients/Hero";
import BlockOne from "../components/patients/BlockOne";
import BlockTwo from "../components/patients/BlockTwo";
import BlockThree from "../components/patients/BlockThree";
import BlockFour from "../components/patients/BlockFour";
import BlockFive from "../components/patients/BlockFive";
import { usePatientsQuery } from "../hooks/usePatientsQuery";
import Helmet from "react-helmet";

const PatientsPage = () => {
    const data = usePatientsQuery();
    
    return (
        <Layout>
            <Hero data={data.patientsHero} />
            <BlockOne data={data} />
            <BlockTwo data={data.patientsBlock3} />
            <BlockThree data={data.patientsBlock4} />
            <BlockFour data={data.patientsFaqs} />
            <BlockFive data={data} />
            <Helmet>
                <title>{data.patientsMetadata.metaTitle}</title>
                <meta name="description" content={data.patientsMetadata.metaDescription} />
            </Helmet>
        </Layout>
    )
};

export default PatientsPage;